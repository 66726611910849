import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTopButton from "../ScrollToTopButton/ScrollToTopButton";

function MainLayouts({ children }) {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
      <ScrollToTopButton />
    </>
  );
}
export default MainLayouts;
