import React from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import MainLayouts from "../components/MainLayouts/MainLayouts";
import Homepage from "../pages/Homepage/Homepage";
import RegisterBusinesspage from "../pages/RegisterBusinesspage/RegisterBusinesspage";
function RouterContainer() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <MainLayouts>
                <Homepage />
              </MainLayouts>
            }
          ></Route>
          <Route
            path="/registerpage"
            element={<RegisterBusinesspage />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default RouterContainer;
