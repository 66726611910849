import "./App.css";
import RouterContainer from "./RouterContainer/RouterContainer";
import "./Assets/css/media.css";

function App() {
  return (
    <div className="App">
      <RouterContainer />
    </div>
  );
}

export default App;
