import React, { useState, useEffect } from "react";
import { PiArrowUpThin } from "react-icons/pi";
import "../../App.css";
import arrow from "../../Assets/images/Arrow.png";

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <img
      src={arrow}
      onClick={scrollToTop}
      className="xl:w-12 xl:h-12 md:w-10 md:h-10  w-8 h-8 bottom-10 md:right-10 right-5 z-50 fixed cursor-pointer"
      style={{
        display: visible ? "" : "none",
      }}
    />
  );
};

export default ScrollToTopButton;
