import React, { useState } from "react";
import logo from "../../Assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { AlignJustify, X, ArrowLeft } from "lucide-react";
import "../../App.css";
import "../../Assets/css/media.css";

function Header() {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="bg-[#111926] text-white border-b border-[#1B212C]">
        <div className="containers">
          <div className=" lg:flex hidden justify-between items-center h-[92px]">
            <div
              onClick={() => {
                navigate("/");
              }}
              className="cursor-pointer"
            >
              <div className="flex items-center">
                <img
                  src={logo}
                  className="xl:w-20 xl:h-20 lg:w-16 lg:h-16"
                  alt="logo"
                />
                <h2 className="4xl:text-2xl  xl:text-lg lg:text-sm font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-cyan-300">
                  CAR WASH NEAR ME
                </h2>
              </div>
            </div>
            <div>
              <div className="items-center  flex justify-start ">
                <Link
                  to="/#howitwork"
                  className="2xl:px-4 px-3 2xl:mx-1.5 mx-0.5 py-2.5 font-medium rounded-full "
                >
                  <div className="flex items-center whitespace-nowrap">
                    <p className="xl:text-base text-sm leading-7 outline-none">
                      How it Work
                    </p>
                  </div>
                </Link>
                <Link
                  to="/"
                  className="2xl:px-4 px-3 2xl:mx-1.5 mx-0.5 py-2.5 font-medium rounded-full"
                >
                  <div className="flex items-center whitespace-nowrap">
                    <p className=" xl:text-base text-sm leading-7 outline-none">
                      About Us
                    </p>
                  </div>
                </Link>
                <Link
                  to="/#faqs"
                  className="2xl:px-4 px-3 2xl:mx-1.5 mx-0.5 py-2.5 font-medium rounded-full"
                >
                  <div className="flex items-center whitespace-nowrap">
                    <p className=" xl:text-base text-sm leading-7 outline-none">
                      FAQs
                    </p>
                  </div>
                </Link>
                <Link
                  to="/#contact"
                  className="2xl:px-4 px-3 2xl:mx-1.5 mx-0.5 py-2.5 font-medium rounded-full"
                >
                  <div className="flex items-center whitespace-nowrap">
                    <p className=" xl:text-base text-sm leading-7 outline-none">
                      Contact Us
                    </p>
                  </div>
                </Link>
                <Link
                  to="/registerpage"
                  className="2xl:px-6 px-4 2xl:ml-5 ml-1 xl:py-3 py-2 font-medium border rounded-full"
                >
                  <div className="flex items-center whitespace-nowrap">
                    <p className="xl:text-base text-sm leading-7 outline-none">
                      Register your business
                    </p>
                  </div>
                </Link>
                <Link
                  to="/"
                  className="2xl:px-14 xl:px-12 px-10 2xl:ml-8 ml-4 xl:py-3 py-2 font-medium bg-[#4169E1]  rounded-full"
                >
                  <div className="flex items-center whitespace-nowrap">
                    <p className=" xl:text-base text-sm leading-7 outline-none">
                      Get The App
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className=" lg:hidden h-[100px] flex justify-between items-center  ">
            <div
              onClick={() => navigate("/")}
              className="flex items-center cursor-pointer"
            >
              <img src={logo} className=" h-16" alt="logo" />
              <h2 className="xxs:text-lg text-base font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-cyan-300 ">
                CAR WASH NEAR ME
              </h2>
            </div>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className=" rounded-[11px] p-2"
            >
              {isOpen ? (
                <span className="text-white">
                  <X />
                </span>
              ) : (
                <span className="text-white">
                  <AlignJustify />
                </span>
              )}
            </button>
          </div>

          {isOpen && (
            <div
              className="fixed inset-0 bg-[#111926] bg-opacity-50 lg:hidden z-10 backdrop-blur-sm"
              onClick={() => setIsOpen(false)}
            ></div>
          )}
          <div
            className={`fixed xs:p-9 p-6 top-0 left-0 h-full bg-[#111926] z-20 transform overflow-auto sm:w-[300px] w-[70%] ${
              isOpen ? "translate-x-0" : "-translate-x-full"
            } transition-transform duration-500 ease-in-out lg:hidden`}
          >
            <div className="flex  items-center">
              <div
                onClick={() => setIsOpen(false)}
                className="text-white cursor-pointer"
              >
                <ArrowLeft />
              </div>
            </div>
            <nav className="space-y-8 ">
              <div className="fonts-500 mt-10 ">
                <Link
                  to="/#howitwork"
                  onClick={() => setIsOpen(false)}
                  className="flex items-center whitespace-nowrap"
                >
                  <p className="xs:text-base text-sm leading-7 outline-none">
                    How it Work
                  </p>
                </Link>
              </div>
              <div className=" fonts-500 ">
                <Link
                  to="/"
                  onClick={() => setIsOpen(false)}
                  className="flex items-center whitespace-nowrap"
                >
                  <p className="xs:text-base text-sm leading-7 outline-none">
                    About Us
                  </p>
                </Link>
              </div>
              <div className=" fonts-500 ">
                <Link
                  to="/#faqs"
                  onClick={() => setIsOpen(false)}
                  className="flex items-center whitespace-nowrap"
                >
                  <p className="xs:text-base text-sm leading-7 outline-none">
                    FAQs
                  </p>
                </Link>
              </div>

              <div className="fonts-500 ">
                <Link
                  to="/#contact"
                  onClick={() => setIsOpen(false)}
                  className="flex items-center whitespace-nowrap"
                >
                  <p className="xs:text-base text-sm leading-7 outline-none">
                    Contact Us
                  </p>
                </Link>
              </div>
              <div className=" fonts-500 ">
                <Link
                  to="/registerpage"
                  onClick={() => setIsOpen(false)}
                  className="flex items-center whitespace-nowrap"
                >
                  <p className="xs:text-base text-sm leading-7 outline-none border rounded-full xs:px-6 px-4 xs:py-2 py-1">
                    Register your business
                  </p>
                </Link>
              </div>
              <div className=" fonts-500 ">
                <Link to="/" className="flex items-center whitespace-nowrap ">
                  <p className=" xs:text-base text-sm leading-7 outline-none xs:px-16 px-12 xs:py-2 py-1 bg-[#4169E1]  rounded-full">
                    Get The App
                  </p>
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
